import { getImage } from "gatsby-plugin-image";
import { GatsbyImage } from "gatsby-plugin-image";
import React from "react";

const Service = ({ title, text, reversed, imgSrc }) => {
  const serviceImage = getImage(imgSrc)
  return (
    <div className="grid sm:grid-cols-2">
      <div
        className={`${
          reversed ? "sm:order-2" : "order-1"
        } h-[25vh] lg:h-[55vh]`}
      >
          <GatsbyImage
            image={serviceImage}
            alt={title}
            style={{ height: '100%', width: '100%' }}
            imgStyle={'cover'}
          />
      </div>
      <div
        className={`${
          reversed ? "sm:order-1" : "order-2"
        } bg-white flex items-center justify-center p-6 py-16 xl:py-20 text-center lg:text-left`}
      >
        <div className="max-w-lg flex flex-col gap-5">
          <p className="text-3xl font-bold lg:text-5xl uppercase ">{title}</p>
          <p className="text-lg">{text}</p>
        </div>
      </div>
    </div>
  );
};

export default Service;
