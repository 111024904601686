import React from "react";
import tw, { styled } from "twin.macro";
import AnimatedText from "react-animated-text-content";
import { GatsbyImage } from "gatsby-plugin-image";
import { getImage } from "gatsby-plugin-image";

const HeroWrapper = styled.header`
  ${tw`h-screen bg-black relative w-full flex items-center justify-center overflow-hidden`}
`;

const Hero = ({hero}) => {
  const {rubrik, bild} = hero;
  const heroImage = getImage(bild.localFile)
  return (
    <HeroWrapper>
      <div className="absolute inset-0 opacity-85">
    
           <GatsbyImage
            image={heroImage}
            alt={rubrik}
            style={{ height: '100%', width: '100%' }}
            imgStyle={'cover'}
          />
      </div>
      <div>
        <div className="max-w-4xl px-6 xl:px-0 text-center">
          <AnimatedText
            type="wave"
            tag="h1"
            className="text-4xl xl:text-8xl leading-tight  text-white"
          >
            {rubrik}
          </AnimatedText>
        </div>
      </div>
    </HeroWrapper>
  );
};

export default Hero;
