import React, { useState } from "react";
import tw, { styled } from "twin.macro";
import { Turn as Hamburger } from "hamburger-react";
import scrollTo from "gatsby-plugin-smoothscroll";
import Menu from "../Menu/Menu"

const NavbarWrapper = styled.header`
  ${tw`flex bg-white px-4 text-white items-center shadow fixed top-0 z-[51] w-full`}
`;
const NavbarInner = styled.header`
  ${tw`flex justify-between items-center flex-1`}
`;

const LogoWrapper = styled.img`
  ${tw`h-16 bg-white m-2`}
`;

const ContactButton = styled.button`
  ${tw`bg-[#F37021] p-3 tracking-wider hover:bg-opacity-50 uppercase font-normal px-8 flex`}
`;

const Navbar = () => {
  const [open, setOpen] = useState(false)
  const navigateTo = (id) => {
    setOpen(false);
    scrollTo(id, "start")
  }
  return (
    <>
      <NavbarWrapper>
        <NavbarInner>
          <div className="order-1 xl:order-0 w-40 flex justify-end xl:justify-start">
            <Hamburger size={34} hideOutline={false} color="#000" toggled={open} toggle={setOpen} />
          </div>
          <div className="order-0 xl:order-1 flex items-center justify-center">
            <button onClick={() => scrollTo("#top")}>
              <LogoWrapper src="./logo.jpg" alt="logotyp"></LogoWrapper>
            </button>
          </div>
          <div className="xl:order-2 w-40 hidden xl:flex justify-end">
            <ContactButton onClick={() => scrollTo("#kontakt")}>
              Kontakt
            </ContactButton>
          </div>
        </NavbarInner>
      </NavbarWrapper>
      <Menu open={open} navigate={(id) => navigateTo(id)}/>
    </>
  );
};

export default Navbar;
