import React from "react";
import Service from "./Service";

const ServiceSection = ({ services }) => {
  return (
    <div className="flex flex-col" id='tjanster'>
      {services?.length &&
        services.map((service, index) => (
          <Service
            key={index}
            imgSrc={service.bild.localFile}
            title={service.rubrik}
            text={service.text}
            reversed={index % 2 === 0}
          />
        ))}
    </div>
  );
};

export default ServiceSection;
