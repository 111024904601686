import { motion } from "framer-motion";
import React from "react";
import tw, { styled } from "twin.macro";

const MenuWrapper = styled(motion.div)`
  ${tw`fixed inset-0 bg-[#F37021] z-50 flex items-center justify-center`}
`;
const MenuContent = styled(motion.ol)`
  ${tw`flex flex-col justify-center text-center gap-8 xl:gap-12`}
`;
const MenuItem = styled.button`
  ${tw`text-3xl xl:text-5xl uppercase text-white hover:text-black`}
`;

const Menu = ({ open, navigate }) => {
  const variants = {
    open: {
      opacity: 1,
      display: "flex",
    },
    closed: {
      opacity: 0,
      transitionEnd: {
        display: "none",
      },
    },
  };
  const container = {
    hidden: { opacity: 0 },
    show: {
      opacity: 1,
      transition: {
        staggerChildren: 0.1,
        type: "spring",
        stiffness: 10000,
        duration: 1,
      },
    },
  };

  const item = {
    hidden: { opacity: 0, y: 150 },
    show: { opacity: 1, y: 0 },
  };



  return (
    <MenuWrapper
      layout
      initial={"closed"}
      animate={open ? "open" : "closed"}
      variants={variants}
    >
      {open && (
        <MenuContent
          variants={container}
          initial="hidden"
          animate="show"
        
        >
          <MenuItem variants={item} onClick={() => navigate("#tjanster")}>
            Tjänster
          </MenuItem>
          <MenuItem variants={item} onClick={() => navigate("#om-oss")}>
            Om oss
          </MenuItem>
          <MenuItem variants={item} onClick={() => navigate("#kontakt")}>
            Kontakt
          </MenuItem>
        </MenuContent>
      )}
    </MenuWrapper>
  );
};

export default Menu;
