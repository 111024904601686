import { useStaticQuery, graphql } from "gatsby";

export const useStartPage = () => {
  const { data } = useStaticQuery(
    graphql`
      query Startsida {
        data: wpPage(title: { eq: "Startsida" }) {
          startsida {
            hero {
              rubrik
              bild {
                localFile {
                  childImageSharp {
                    gatsbyImageData
                  }
                }
              }
            }
            omOss {
              rubrik
              text
            }
            kontakt {
              namn
              email
              telefon
              adress
              orgnr
            }
            tjanster {
              tjanst {
                rubrik
                text
                bild {
                  localFile {
                    childImageSharp {
                      gatsbyImageData
                    }
                  }
                }
              }
            }
          }
        }
      }
    `
  );
  return data;
};
