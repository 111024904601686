import React from "react";
import AboutSection from "../components/AboutSection/AboutSection";
import ContactSection from "../components/ContactSection/ContactSection";
import Hero from "../components/Hero/Hero";
import Layout from "../components/Layout/Layout";
import ServiceSection from "../components/ServiceSection/ServiceSection";
import { useStartPage } from "../hooks/use-start-page";
import { Helmet } from "react-helmet";

const StartPage = () => {
  const startPageData = useStartPage();
  const hero = startPageData.startsida.hero;
  const about = startPageData.startsida.omOss;
  const services = startPageData.startsida.tjanster.tjanst;
  const contact = startPageData.startsida.kontakt;
  return (
    <Layout>
      <Helmet>
        <title>Elektrobyrån AB | Din elektriker i Stockholm, Åkersberga</title>
      </Helmet>
      <Hero hero={hero} />
      <ServiceSection services={services} />
      <AboutSection about={about} />
      <ContactSection contact={contact} />
    </Layout>
  );
};

export default StartPage;
