import React from "react";
import AnimatedText from "react-animated-text-content";
import tw, { styled } from "twin.macro";

const SectionWrapper = styled.section`
  ${tw`text-sm py-16 xl:py-24`};
  ${({ bg }) =>
    bg &&
    `
    background-color: ${bg};
  `};
  ${({ color }) =>
    color &&
    `color: ${color};
  `};
`;

const Section = ({
  pretext = "PRETEXT",
  children,
  color = "black",
  id = "section",
  bg,
  title = "Rubrik",
  text,
}) => {
  return (
    <SectionWrapper id={id} bg={bg} color={color}>
      <div className="container mx-auto px-6 max-w-3xl text-center flex flex-col gap-7">
        <div className="flex flex-col gap-3 tracking-wider">
          <AnimatedText
            type="words"
            tag="div"
            className="text-3xl font-bold xl:text-5xl uppercase "
          >
            {title}
          </AnimatedText>
        </div>
        {text && <p className="text-lg">{text}</p>}

        {children}
      </div>
    </SectionWrapper>
  );
};

export default Section;
