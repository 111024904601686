import React from 'react'
import Section from '../Section/Section'

const ContactSection = ({contact}) => {
  const {namn, email, telefon, adress, orgnr} = contact;
  return (
    <Section id={"kontakt"} bg="white" title='Kontakt'>
      <div className='flex flex-col gap-4 text-4xl'>
        <p>{namn}</p>
        <a href={`mailto:${email}`}>{email}</a>
        <p>{telefon}</p>
        <p>{adress}</p>
        <p>{orgnr}</p>
      </div>
    </Section>
  )
}

export default ContactSection