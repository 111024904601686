import React from 'react'
import Section from '../Section/Section'

const AboutSection = ({about}) => {
  const {rubrik, text} = about;
  return (
    <Section id='om-oss' bg={'#F46E21'} color={"white"} title={rubrik} text={text}></Section>
  )
}

export default AboutSection